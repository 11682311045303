<template>
    <el-tabs type="border-card" class="m-2" style="height:90vh; overflow: scroll;">
        <el-tab-pane label="Etat" v-if="permissions[0].includes('etat')">
           <EtatPage v-bind:permissions="permissions['etat']"/>
        </el-tab-pane>
        <el-tab-pane label="Réunion" v-if="permissions[0].includes('metting')">
           <MeetingPage v-bind:permissions="permissions" />
        </el-tab-pane>
        <el-tab-pane label="Inventaire" v-if="permissions[0].includes('inventaire')">
           <InventairePage v-bind:permissions="permissions" />
        </el-tab-pane>
        <el-tab-pane label="Incident" v-if="permissions[0].includes('incident')">
           <IncidentPage v-bind:permissions="permissions" />
        </el-tab-pane>
    </el-tabs>
</template>
<script>

    import EtatPage from './Report/EtatPage.vue';
    import MeetingPage from './Report/MeetingPage.vue';
    import IncidentPage from './Report/IncidentPage.vue';
    import InventairePage from './Report/InventairePage.vue';

    export default {

        components: {
            EtatPage,
            IncidentPage,
            InventairePage,
            MeetingPage
        },

        data(){

            return {
                permissions: [],
            }
        },

        created(){
            this.permission();
        },
        
        methods: {

            permission() {
                this.permissions =  JSON.parse(localStorage.getItem('menu'));
                this.permissions = this.permissions.report;
            },

        },
        
    }
    
</script> 